<template>
  <div class="main__content">
    <b-card
      no-body
      class="p-1"
    >
      <Billing
        :is-my-billing="false"
        :is-member="isMember"
        :select-enabled="!isMember"
        :dates-lists="datesLists"
        :data-lists="dataLists"
        :summary="summary"
        @onGetDates="onGetDates"
        @onGetData="onGetData"
        @onConfirmPaid="onConfirmPaid"
        @onConfirmPending="onConfirmPending"
      />
    </b-card>
  </div>
</template>

<script>
import Billing from '@/components/billing/Billing.vue'
import store from '@/store'
import { GetAgentGroupId, GetAgentGroupUsername, GetAgentGroupType } from '@/utils/agent'

export default {
  components: {
    Billing,
  },
  data() {
    return {
      datesLists: [],
      dataLists: [],
      summary: {},
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
    isMember() {
      const currentUserType = GetAgentGroupType(this.userData)
      return currentUserType === 'AGENT'
    },
  },
  methods: {
    GetAgentGroupId,
    GetAgentGroupUsername,
    GetAgentGroupType,
    async onGetDates() {
      try {
        const { data } = await this.$http.get('/billing/getDates')
        const dates = data?.data?.dates ?? []
        this.datesLists = dates
      } catch (error) {
        this.datesLists = []
      }
    },

    async onGetData(params) {
      const { cb } = params

      this.$wait.start('loading-fetch-getReportDownlineBillingByWeek')
      try {
        const weekNumber = params.selectedDate
        // const { data } = await this.$http.get(`/reports/getReportDownlineBillingByWeek/${weekNumber}`)
        const { data } = await this.$http.get(`/billing/v2/getReportDownlineBillingByWeek/${weekNumber}`)
        this.dataLists = data?.data?.lists ?? []
        this.summary = data?.data?.summary ?? {}
      } catch (error) {
        this.dataLists = []
        this.summary = {}
      } finally {
        this.$wait.end('loading-fetch-getReportDownlineBillingByWeek')
        cb()
      }
    },

    onConfirmPaid({
      selectedRows,
      selectedDate,
    }) {
      return this.updateDownlineByWeek({
        selectedRows,
        selectedDate,
        isPaid: true,
      })
    },
    onConfirmPending({
      selectedRows,
      selectedDate,
    }) {
      return this.updateDownlineByWeek({
        selectedRows,
        selectedDate,
        isPaid: false,
      })
    },
    async updateDownlineByWeek({
      selectedRows,
      selectedDate,
      isPaid,
    }) {
      try {
        const agents = selectedRows.map(list => ({ id: list.group._id, username: list._id, paid: isPaid }))
        const selectedRowsIds = selectedRows.map(list => list._id)
        const weekNumber = selectedDate
        const { data } = await this.$http.post(`/billing/updateDownlineByWeek`, {
          weekNumber,
          agents,
        })
        const success = data?.success ?? false
        if (success) {
          for (let index = 0; index < this.dataLists.length; index += 1) {
            const dataList = this.dataLists[index];
            if (selectedRowsIds.includes(dataList._id)) {
              this.$set(this.dataLists, index, {
                ...dataList,
                paid: isPaid,
              })
            }
          }
          this.$swal({
            icon: 'success',
            title: this.$t('Success'),
          })
        } else {
          this.$swal({
            icon: 'error',
            title: data?.status?.message ?? this.$t('Error'),
          })
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t('Error'),
        })
      }
    },
  },
}
</script>

<style>

</style>
